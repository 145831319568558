import { ApiPath } from '~/enum/apiPath'
import http from '~/http-common'
import type { StandardResponse } from '~~/types/httpResponseData'

class NoticeDatasService {
  /**
   * manager/F09020/goへのPOSTを行う
   * @param f09020dto お知らせのdto
   * @param files 添付ファイル
   * @returns HttpResponseData
   */
  update = async (formData: FormData): Promise<number> => {

    const path = ApiPath.NOTICE_DATAS_UPDATE
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
      'Content-type': 'multipart/form-data',
    }
    const response: StandardResponse = await http.post(path, formData, { headers: headers })

    return response.data.code
  }
}

export default new NoticeDatasService()
