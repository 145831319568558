import http from '~/http-common'
import type { F09019Params } from '~~/types/F09019Params'
import { makeQueryStrings } from '~/libs/makeQueryStrings'
import type { NoticeRes } from '~~/types/noticeRes'
import { ApiPath } from '~/enum/apiPath'

class GetNoticeDatasService {
  get(params: F09019Params): Promise<NoticeRes> {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      'Content-type': 'application/json; charset=utf-8',
      accept: 'application/json, text/javascript, */*; q=0.01',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const url = ApiPath.GET_NOTICE_DATAS_GET
    const queryStrings: string = makeQueryStrings(params)
    return http.get(url + queryStrings, { headers: headers })
  }
}

export default new GetNoticeDatasService()
