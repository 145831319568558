import Quill from 'quill'
/**
 * textareaに入力した文字列をQuillエディタを通してhtml形式に変換して返却する関数
 * @param text textareaに入力した文字列
 * @returns DB登録用にhtml形式に変換した文字列
 */
export function convertHtmlText (text: string) {
  // 改行で分割
  const inputText = text.split(/\n/)
  const returnText: string[] = []
  inputText.forEach(element => {
    // テキスト抽出用にQuillエディタを作成して、画面に非表示で一時設置
    const div = document.createElement('div')
    div.setAttribute('id', 'htmlToDelta')
    // htmlテキストをQuillエディタにする要素内にセット
    div.innerHTML = `<div id="quillEditor" style="display:none">`+ element +`</div>`
    document.body.appendChild(div)
    const quill = new Quill('#quillEditor')
    // html形式に変換して1行ずつ配列に追加
    returnText.push(quill.root.innerHTML)
    // Quillエディタを削除
    document.getElementById('htmlToDelta')!.remove()
  })
  return returnText.join('')
}
